<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <!-- <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="模糊搜索名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.product_code" @change="Search" placeholder="产品编号"></el-input>
        </el-form-item>
      </el-form>
      <div>
          <router-link :to="'/iot/packadd/'+0">
            <el-button type="primary" size="small">新建</el-button>
          </router-link>
      </div>
    </div> -->
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="型号名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品型号">
        <template v-slot="scope">
          <span>{{ scope.row.model }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <el-button type="primary" v-if="!scope.row.hasDis" size="small" @click="distribute(scope.row)">分配</el-button>
          <el-button type="danger" v-else size="small" @click="cancel(scope.row.deleId)">取消分配</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../../axios/pack'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
      },
      list: [],
      total: 0,
      Packcode: '',
      packmodellist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.distriList()
    },
    distriList () {
      api.ProPackDistriList({
        page: 1,
        limit: 100,
        set_meal_code: this.Packcode
      }).then(res => {
        if (res.data.code == 200) {
          this.packmodellist = res.data.data.data
          this.GetModel()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetModel () {
      this.api.ProductModelList({
        page: this.search.page,
        limit: this.search.limit,
      }).then(res => {
        if (res.data.code == 200) {
          if (this.packmodellist.length !== 0) {
            let brr = []
            let temp = {}
            res.data.data.data.forEach(item => {
              for(let i=0;i<this.packmodellist.length;i++){
                if (this.packmodellist[i].product_model_code == item.code) {
                  temp = item
                  temp.hasDis = true
                  temp.deleId = this.packmodellist[i].id
                  brr.push(temp)
                  return
                } else if(i == this.packmodellist.length-1) {
                  temp = item
                  temp.hasDis = false
                  brr.push(temp)
                }
              }
            })
            this.list = brr
          } else {
            this.list = res.data.data.data
          }
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    distribute (item) {
      api.ProPackDistriUpdate({
        product_model_code: item.code,
        set_meal_code: this.Packcode
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('分配成功')
          this.distriList()
          return
        }
        this.$message.error('分配失败' + res.data.msg)
      })
    },
    cancel (item) {
        api.ProPackDistriDelete({
          id: item
        }).then(res => {
          if (res.data.code == 200) {
            this.$message.success('取消分配成功')
            this.distriList()
            return
          }
          this.$message.error('取消分配失败' + res.data.msg)
        })
    },
    goback () {
      this.$router.push('/iot/pack')
    }
  },
  filters: {},
  mounted () {
    
  },
  created () {
    this.Packcode = this.$route.params.code
    this.distriList()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
